import './index.scss';
import Elogo from '../../../assets/images/Elogo.png'
const Logo = () => {

    

    return (
        <div className="logo-container">
            <img className="solid-logo" src={Elogo} alt="E" />
        </div>
    )
}

export default Logo;