import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import { useEffect, useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
const Contact = () => {


    const [letterClass, setLetterClass] = useState('text-animate')
    const refForm = useRef()

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    
        
        return () => clearTimeout(timeoutId)
    }, [])

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs
            .sendForm(
                'service_enq91qk',
                'template_47b3rjg',
                refForm.current,
                'lHvmms-wUih4dkoEL'
            )
            .then(
                () => {
                    alert('Email sent successfully')
                    window.location.reload(false)
                },
                () => {
                    alert('Email failed to send')
                }
            )
    }

    return (
        <>
            <div className="container contact-page">
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'M', 'e']}
                            idx={15}
                        />
                    </h1>
                    <p>
                        I am interested in hearing about new opportunities and projects. If you have a project you would like to discuss, please feel free to reach out to me. I am always looking for new opportunities to learn and grow as a developer.
                    </p>
                    <div className="contact-form">
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className="half">
                                    <input type="text"  name="name" placeholder="Name" required />
                                </li>
                                <li className="half">
                                    <input type="email"  name="email" placeholder="Email" required />
                                </li>
                                <li>
                                    <input placeholder="Subject" type="text" name="subject" required />
                                </li>
                                <li>
                                    <textarea placeholder="Message" name="message" required></textarea>
                                </li>
                                <li>
                                    <input type="submit" className="flat-button" value="SEND" />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
                <div className='info-map'>
                    Evan Bucholski,
                    <br />
                    Canada,
                    <br />
                    University of Guelph, <br />
                    1280 Gordon Street <br />
                    <span>ebuchol@outlook.com</span>
                </div>
                <div className='map-wrap'>
                    <MapContainer center={[43.516258, -80.200447]} zoom={13}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[43.961950, -78.948710]}>
                            <Popup>
                                Evan Lives Here!
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
        </>
    )

}


export default Contact;


