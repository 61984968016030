import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3, faReact, faGitAlt, faPython, faJava } from '@fortawesome/free-brands-svg-icons';
const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    
        
        return () => clearTimeout(timeoutId)
    }, [])


    return (
        <div className='container about-page'>
            <div className='text-zone'>

                <h1>
                    <AnimatedLetters
                        letterClass={letterClass}
                       strArray={['A', 'b', 'o', 'u', 't', ' ', 'M', 'e']} 
                       idx={15}
                    />
                </h1>
                <p>
                    Hello, I'm Evan. I am currently enrolled in Software Engineering at the University of Guelph
                </p>
                <p>
                    I am passionate about software development and I am always looking to learn new technologies and improve my skills
                </p>
                <p>
                    I have experience with a variety of programming languages and technologies, including Python, Java, HTML, CSS, JavaScript, React, C, and Git
                </p>
            </div>

            <div className='stage-cube-cont'>
                <div className='cubespinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon={faPython} color="yellow" />
                    </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                    </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
                    </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                    </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon={faJava} color="orange" />
                    </div>
                    <div className='face6'>
                        <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;